




































































































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      loading: true,
      paginate: {
        keywords: '',
        page: 1,
        size: 10,
        total: 0,
        orderBy: {},
        status: '',
        startTime: '',
        endTime: '',
      },
      entities: [],
      selection: [],
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      const res = await this.$http.request({
        url: 'orders',
        method: 'get',
        params: this.paginate,
      })
      this.entities = res.data.data
      this.loading = false
      this.paginate.total = res.data.meta.total
    },
    resetSearch() {
      this.paginate.keywords = ''
      this.paginate.page = 1
      this.paginate.status = ''
      this.paginate.startTime = ''
      this.paginate.endTime = ''
      this.fetch()
    },
    handleSizeChange(size: number) {
      this.paginate.size = size
      this.fetch()
    },
    handleCurrentChange(page: number) {
      this.paginate.page = page
      this.fetch()
    },
    handleSortChange({ prop, order }: { prop: any; order: any }) {
      if (order) {
        this.paginate.orderBy = {
          prop,
          order: order.replace('ending', ''),
        }
      } else {
        this.paginate.orderBy = {}
      }
      this.fetch()
    },
    handleSelectionChange(val: any) {
      this.selection = val.map((i: any) => i.id)
    },
    async handleExport() {
      const response = await this.$http.post(
        'orders/export',
        {
          ...this.paginate,
          paginate: 0,
        },
        {
          responseType: 'arraybuffer',
        },
      )
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      })
      let downloadElement = document.createElement('a')
      let href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `table.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    },
    async handleResetCode(order: any) {
      const ok = await this.$confirm(
        `确定要还原激活码：${order.code}吗?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        },
      )
      if (ok) {
        await this.$http.put(`codes/reset/${order.code}`)
        this.$message.success('还原成功')
        this.fetch()
      }
    },
    getCost(row: any) {
      const price = row?.price || 0
      const discount = row?.wallet?.discount || 1
      const rate = row?.wallet?.rate?.value || 1

      console.log(price, discount, rate)
      return (price * discount * rate).toFixed(2)
    },
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
})
